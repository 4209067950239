<template>
  <copy-button :disabled="disabled" :text="text">
    <span class="button-text text-headline truncate">
      <span v-if="separator"> , </span>
      {{ text || missingValueText }}
    </span>
  </copy-button>
</template>
<script>
import CopyButton from "@/components/ui/buttons/CopyButton.vue";
export default {
  components: {
    CopyButton
  },
  props: {
    text: {
      type: [String, Number],
      default: ""
    },
    missingValueText: {
      type: String,
      default: "-"
    },
    separator: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.button-text {
  word-break: break-word;
}
</style>
